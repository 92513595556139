import {
  DownloadIcon,
  InfoIcon,
  RepeatClockIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CircularProgress,
  Heading,
} from "@chakra-ui/react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoaderButton } from "../../components/LoaderButton";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import { Wrapper } from "../../components/Wrapper";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { Colaborador } from "../colaborador/Colaborador";
import { formataVencimento } from "../microemprestimo/formataVencimento";
import { HolerithPdf } from "./HolerithPdf";
import { holerithActions } from "./reducer";
import colorConfig from "../../config/colorConfig";

export const HolerithLista: React.FC<{}> = () => {
  useIsAuth();

  const navigate = useNavigate();

  const holeriths = useSelector((state: RootState) => state.holerith.holeriths);
  const error = useSelector((state: RootState) => state.holerith.error);
  const isLoading = useSelector((state: RootState) => state.holerith.isLoading);
  const isHolerithLoading = useSelector(
    (state: RootState) => state.holerith.isHolerithLoading
  );
  const holerith = useSelector((state: RootState) => state.holerith.holerith);
  const folhaPontob64 = useSelector(
    (state: RootState) => state.holerith.folhaPontob64
  );
  const isFolhaPontoLoading = useSelector(
    (state: RootState) => state.holerith.isFolhaPontoLoading
  );

  const viewHolerith = (holerithUkey: string) => {
    dispatch(holerithActions.requestHolerith(holerithUkey));
  };

  const viewFolhaPonto = (periodo: string) => {
    dispatch(holerithActions.requestFolhaPonto(periodo));
  };

  const downloadFolhaPonto = (periodo: string) => {
    dispatch(holerithActions.downloadFolhaPonto(periodo));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(holerithActions.requestHoleriths());
  }, [dispatch]);

  const headers: TableHeaders<any>[] = [
    {
      label: "Mês",
      wrapped: true,
      render: (holerith) => formataVencimento(holerith.anomes),
    },
    {
      label: "Tipo",
      wrapped: true,
      render: (holerith) => holerith.tipo_folha?.codigo,
    },
    {
      label: "Holerite",
      wrapped: true,
      render: (holerithItem) => (
        <>
          <LoaderButton
            colorScheme="gray"
            onClick={() => viewHolerith(holerithItem.ukey)}
            mx="0.5rem"
            isLoading={isHolerithLoading}
          >
            <ViewIcon />
          </LoaderButton>

          <PDFDownloadLink document={<HolerithPdf holerith={holerithItem} />}>
            <Button colorScheme="blackAlpha" _hover={{ backgroundColor: colorConfig.primaryColor }} bg={colorConfig.primaryColor} mx="0.5rem">
              <DownloadIcon />
            </Button>
          </PDFDownloadLink>

          {holerithItem.tipo_folha?.codigo == 'MENSAL' && <Button
            colorScheme="blackAlpha"
            mx="0.5rem"
            title="Ver gastos dos convenios"
            onClick={() => navigate(`/gastos-convenio/${holerithItem.anomes}`)}
            _hover={{ backgroundColor: colorConfig.primaryColor }} bg={colorConfig.primaryColor}
          >
            <InfoIcon />
          </Button>}
        </>
      ),
    },
    {
      label: "Folha ponto",
      wrapped: true,
      render: (holerithItem) => (
        <>
          <LoaderButton
            colorScheme="gray"
            mx="0.5rem"
            onClick={() => viewFolhaPonto(holerithItem.anomes)}
            isLoading={isFolhaPontoLoading}
          >
            <RepeatClockIcon />
          </LoaderButton>
          <LoaderButton
            colorScheme="gray"
            mx="0.5rem"
            onClick={() => downloadFolhaPonto(holerithItem.anomes)}
            isLoading={isFolhaPontoLoading}
          >
            <DownloadIcon />
            <RepeatClockIcon fontSize="0.7rem" mt="0.5rem" />
          </LoaderButton>
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Colaborador />
      <Heading>Ultimos holerites</Heading>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {isLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} />}
      {!isLoading && <ResponsiveTable headers={headers} data={holeriths} />}
      {holerith && holerith.ukey && (
        <>
          <PDFDownloadLink document={<HolerithPdf holerith={holerith} />}>
            <DownloadIcon /> Download PDF
          </PDFDownloadLink>
          <PDFViewer style={{ width: "100%", height: "80ch" }}>
            <HolerithPdf holerith={holerith} />
          </PDFViewer>
        </>
      )}
      {folhaPontob64 && (
        <iframe
          title="pdfview"
          src={`data:application/pdf;base64,${folhaPontob64}`}
          style={{ width: "90vw", height: "60vh" }}
        ></iframe>
      )}
    </Wrapper>
  );
};
